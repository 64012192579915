import VendorSwiper, { Navigation, EffectFade } from "swiper";

class Swiper extends VendorSwiper {
  constructor(id, options) {
    super(id, {
      ...options,
      fadeEffect: {
        crossFade: true,
      },
      modules: [Navigation, EffectFade],
    });
  }
}

window.Swiper = Swiper;

export default Swiper;
